import { Space } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
import routes from "@/routes";
import { ProductType } from "@/utils/types";
import QRCode from "qrcode.react";
import NextIcon from "@/assets/icons/NextIcon";
import EditIcon from "@/assets/icons/EditIcon";

export const columns = () =>
  [
    {
      title: "Product ID",
      dataIndex: "identityCode",
      key: "identityCode",
      width: 100,
      render: (text, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            display: "block",
            maxWidth: 100,
          }}
        >
          {record?.productCode}
        </span>
      ),
    },
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
      width: 100,
      render: (text, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            display: "block",
            maxWidth: 100,
          }}
        >
          {record?.productName}
        </span>
      ),
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      width: 100,
      render: (text, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            display: "block",
            maxWidth: 100,
          }}
        >
          {record?.bin?.warehouse?.company?.companyName}
        </span>
      ),
    },
    {
      title: "Warehouse Code",
      dataIndex: "wareHouseId",
      key: "wareHouseId",
      width: 120,
      render: (text, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            display: "block",
            maxWidth: 120,
          }}
        >
          {record?.bin?.warehouse?.identityCode}
        </span>
      ),
    },
    {
      title: "Bin Code",
      dataIndex: "binCode",
      key: "binCode",
      width: 100,
      render: (text, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            display: "block",
            maxWidth: 100,
          }}
        >
          {record.bin.binCode}
        </span>
      ),
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      width: 100,
      render: (text, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            display: "block",
            maxWidth: 100,
          }}
        >
          {record.position}
        </span>
      ),
    },
    {
      title: "QR Code",
      dataIndex: "qrCode",
      key: "qrCode",
      width: 100,
      render: (t, record) => (
        <>
          <QRCode
            style={{ width: 50, height: 50 }}
            value={JSON.stringify({
              productId: record.id,
              companyId: record.bin.warehouse?.companyId,
            })}
          />
        </>
      ),
    },
    {
      title: "Inventory",
      dataIndex: "inventory",
      key: "inventory",
      width: 100,
      render: (text, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            display: "block",
            maxWidth: 100,
          }}
        >
          {record.inventory}
        </span>
      ),
    },
    {
      title: "Minimum Quantity",
      dataIndex: "minQ",
      key: "minQ",
      width: 100,
      render: (text, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            display: "block",
            maxWidth: 100,
          }}
        >
          {record.minimumQuantity}
        </span>
      ),
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      width: 100,
      render: (text, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            display: "block",
            maxWidth: 100,
          }}
        >
          {record.unit}
        </span>
      ),
    },
    {
      title: "Functions",
      key: "action",
      width: 160,
      render: (_, record) => {
        return (
          <Space
            size="middle"
            style={{
              fontSize: 12,
            }}
          >
            <Link to={routes.viewSiteProduct.path.replace(":id", record.id)}>
              <NextIcon style={{ width: 16, cursor: "pointer" }} />
            </Link>
              <Link to={routes.editSiteProduct.path.replace(":id", record.id)}>
                <EditIcon style={{ width: 16, cursor: "pointer" }} />
              </Link>
          </Space>
        );
      },
    },
  ] as ColumnsType<ProductType>;
