import { Button, Divider, Input, InputRef, Select, Space } from "antd";
import { CSSProperties, useEffect, useRef, useState } from "react";
import useDebounce from "@/hooks/useDebounce";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { BaseOptionType } from "antd/es/select";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useGetBinListQuery } from "@/rtk-query/binQueries";
import { PlusOutlined } from "@ant-design/icons";
import { useGetPositionListQuery } from "@/rtk-query/positionQueries";

interface IPositionDropdownListProps {
  value?: string | number | null;
  onChange: (dataPosition: any) => void;
  disabled?: boolean;
  filter?: any;
  search?: string;
  className?: string;
  style?: CSSProperties;
  skip?: boolean;
}

const PositionDropdownList = ({ value, onChange, disabled, filter = {}, search = "", style, skip, className }: IPositionDropdownListProps) => {
  const newItemInputRef = useRef<InputRef>(null);
  const [searchPosition, setSearchPosition] = useState("");
  const [newPostion, setNewPostion] = useState("");
  const [items, setItems] = useState<any[]>([""]);
  const searchPositionDebounce = useDebounce(searchPosition, 300);
  const { currentData: list, isFetching: isFetchingPosition } = useGetPositionListQuery(
    {
      ...filter,
      search: searchPositionDebounce,
    },
    { skip, refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    setSearchPosition(search);
  }, [search]);

  useEffect(() => {
    setItems(list || [""]);
  }, [isFetchingPosition]);

  const dropdownStyle = {
    background: "#dfe3ef",
    padding: 0,
    borderRadius: 0,
    boxShadow: "inset 4px 4px 3px rgba(0, 0, 0, 0.03)",
    fontSize: 12,
    fontWeight: 600,
  };

  const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault();
    setItems(items.length > 0 ? [...items, newPostion] : [newPostion]);
    setNewPostion("");
    setTimeout(() => {
      newItemInputRef.current?.focus();
    }, 0);
  };

  const onChangeNewPostion = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPostion(event.target.value);
  };

  return (
    <Select
      style={
        style || {
          color: "#234C66",
        }
      }
      className={className}
      placeholder="Type position..."
      showSearch
      onSearch={(value) => setSearchPosition(value)}
      loading={isFetchingPosition}
      dropdownStyle={dropdownStyle}
      size="middle"
      popupClassName="customDropdownSelectAntd"
      allowClear
      onClear={() => onChange(null)}
      disabled={disabled}
      getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
      filterOption={false}
      value={value}
      onChange={(_, children: BaseOptionType) => {
        onChange(children?.value);
      }}
      // eslint-disable-next-line react/no-unstable-nested-components
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: "8px 0" }} />
          <Space style={{ padding: "0 8px 4px" }}>
            <Input
              ref={newItemInputRef}
              placeholder="Please enter item"
              value={newPostion}
              onChange={onChangeNewPostion}
              onKeyDown={(e) => e.stopPropagation()}
            />
            <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
              Add position
            </Button>
          </Space>
        </>
      )}
      options={items.map((item) => ({ value: item.position }))}
    />
  );
};

export default PositionDropdownList;
